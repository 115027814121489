import React, { createContext, useContext, useState } from 'react';

const SharedDataContext = createContext();

export const useSharedData = () => useContext(SharedDataContext);

export const SharedDataProvider = ({ children }) => {

    const [selectedRecord, setSelectedRecord] = useState(null);
    const [detailViewType, setDetailViewType] = useState('CallDetail');
    const [lastMessage, setLastMessage] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [dateRange, setDateRange] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    return (
        <SharedDataContext.Provider value={{ searchQuery, setSearchQuery, dateRange, setDateRange, showNotification, setShowNotification, lastMessage, setLastMessage, selectedRecord, setSelectedRecord, detailViewType, setDetailViewType }}>
            {children}
        </SharedDataContext.Provider>
    );
};
