import React from 'react';
import { Descriptions, Typography } from 'antd';
import EditableDescription from "./widgets/EditableDescription";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import _ from 'lodash';

const { Paragraph } = Typography;

const DetailView = ({ call, setSelectedCall }) => {
    const { getAccessTokenSilently } = useAuth0();

    if (!call) {
        return <div>Select a call from the list to view details.</div>;
    }

    const updateCallField = async (callId, field, value) => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: "https://audience.auth0.leadlensai.com",
                scope: "openid profile email",
            });
            await axios.patch(`/api/calls/${callId}`, { [field]: value }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error updating call field:', error);
            throw error;
        }
    };

    const handleSaveField = async (field, updatedValue) => {
        try {
            console.log(`Updating ${field} on Call ${call.id} to ${updatedValue}`);
            await updateCallField(call.id, field, updatedValue);
            _.set(call, field, updatedValue);
            setSelectedCall(call);
        } catch (error) {
            console.error('Error updating field:', error);
        }
    };

    return (
        <div className={'detail-view'}>
            <div className={"detail-header"}>
                <h1><span>{call.customer_name}</span></h1>
                <h2>{call.company_name} -  {call.ai_q1}</h2>
            </div>

            <Descriptions title="Contact Information" bordered column={1}>
                <Descriptions.Item label="Phone Number">
                    <EditableDescription item={call.customer_phone_number} onSave={(value) => handleSaveField('customer_phone_number', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Email Address">
                    <EditableDescription item={call.customer_email} onSave={(value) => handleSaveField('customer_email', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Date / Time">{call.start_time}</Descriptions.Item>
                <Descriptions.Item label="Type of Inquiry">
                    <EditableDescription item={call.ai_q1} inputType={'dropdown'} field={'ai_q1'} onSave={(value) => handleSaveField('ai_q1', value)} />
                </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Lead Ranking" bordered column={1}>
                <Descriptions.Item label="Rating">
                    <EditableDescription
                        item={call.ai_q2}
                        inputType="slider"
                        onSave={(value) => handleSaveField('ai_q2', value)}
                        ratingBadgeProps={{
                            containerDisplay: 'inline',
                            radius: '35px',
                            dynamicColor: true
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Marketing Source">{call.source}</Descriptions.Item>
                <Descriptions.Item label="Follow Up Needed">
                    <EditableDescription item={call.ai_q3}  inputType={'dropdown'} options={['Yes','No']}  onSave={(value) => handleSaveField('ai_q3', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Potential Move-In Date">
                    <EditableDescription item={call.ai_q5}  inputType={'dropdown'} field={'ai_q5'} onSave={(value) => handleSaveField('ai_q5', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Floor Plans of Interest">
                    <EditableDescription item={call.ai_q4}  inputType={'dropdown'} field={'ai_q4'} onSave={(value) => handleSaveField('ai_q4', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Affordable Housing Request">
                    <EditableDescription item={call.ai_q7}   inputType={'dropdown'} options={['Yes','No']}   onSave={(value) => handleSaveField('ai_q7', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Call ID">{call.id}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Shop Ranking" bordered column={1}>
                <Descriptions.Item label="Agent Name">
                    <EditableDescription item={call.questions?.AgentName} onSave={(value) => handleSaveField('questions.AgentName', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Gave Name">
                    <EditableDescription item={call.questions?.AgentIntro} inputType={'dropdown'} options={['Yes','No']} onSave={(value) => handleSaveField('questions.AgentIntro', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Collected Contact Name">
                    <EditableDescription item={call.questions?.AgentCollectedName} onSave={(value) => handleSaveField('questions.AgentCollectedName', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Collected Contact Info">
                    <EditableDescription item={call.questions?.AgentCollectedEmailAndPhone} onSave={(value) => handleSaveField('questions.AgentCollectedEmailAndPhone', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Collected Caller Needs">
                    <EditableDescription item={call.questions?.AgentCollectedNeeds} onSave={(value) => handleSaveField('questions.AgentCollectedNeeds', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Responsive to Questions">
                    <EditableDescription
                        item={call.questions?.AgentResponsiveToQuestions}
                        inputType="slider"
                        onSave={(value) => handleSaveField('questions.AgentResponsiveToQuestions', value)}
                        ratingBadgeProps={{
                            colorFontOnly: true,
                            containerDisplay: 'inline',
                            radius: '35px',
                            dynamicColor: true
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Handled Objections">
                    <EditableDescription
                        item={call.questions?.AgentHandledObjections}
                        inputType="slider"
                        onSave={(value) => handleSaveField('questions.AgentHandledObjections', value)}
                        ratingBadgeProps={{
                            colorFontOnly: true,
                            containerDisplay: 'inline',
                            radius: '35px',
                            dynamicColor: true
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Defined Follow-Up">
                    <EditableDescription item={call.questions?.AgentDefinedFollowUp} onSave={(value) => handleSaveField('questions.AgentDefinedFollowUp', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Offered Tour">
                    <EditableDescription item={call.questions?.AgentOfferedTour} onSave={(value) => handleSaveField('questions.AgentOfferedTour', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Demeanor Score">
                    <EditableDescription
                        item={call.questions?.AgentDemeanor}
                        inputType="slider"
                        onSave={(value) => handleSaveField('questions.AgentDemeanor', value)}
                        ratingBadgeProps={{
                            colorFontOnly: true,
                            containerDisplay: 'inline',
                            radius: '35px',
                            dynamicColor: true
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Sales Score">
                    <EditableDescription
                        item={call.questions?.AgentSalesSkills}
                        inputType="slider"
                        onSave={(value) => handleSaveField('questions.AgentSalesSkills', value)}
                        ratingBadgeProps={{
                            colorFontOnly: true,
                            containerDisplay: 'inline',
                            radius: '35px',
                            dynamicColor: true
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Call Sentiment">
                    <EditableDescription item={call.questions?.CallSentiment} onSave={(value) => handleSaveField('questions.CallSentiment', value)} />
                </Descriptions.Item>
                <Descriptions.Item label="Agent Evaluation">
                    <EditableDescription
                        item={call.questions?.AgentSummary}
                        inputType="paragraph"
                        onSave={(value) => handleSaveField('questions.AgentSummary', value)}
                    />
                </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Short Call Summary" bordered column={1}>
                <Descriptions.Item>
                    <EditableDescription
                        item={call.ai_q8}
                        inputType="paragraph"
                        onSave={(value) => handleSaveField('ai_q8', value)}
                    />
                </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Full Call Transcript" bordered column={1}>
                <Descriptions.Item>
                    <div dangerouslySetInnerHTML={{ __html: call.htmlTranscription }} />
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};

export default DetailView;
