import React, { useState, useEffect } from 'react';
import { Layout, Menu, Select, Typography } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
    PhoneOutlined,
    UserOutlined,
    LoginOutlined,
    LogoutOutlined,
    BankOutlined,
    CustomerServiceOutlined,
    SettingOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;
const { Option } = Select;
const { Title } = Typography;

const NavBar = ({ onCompanyNamesChange }) => {
    const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
    const [companyNames, setCompanyNames] = useState([]);
    const navigate = useNavigate(); // Import useNavigate hook

    useEffect(() => {
        if (isAuthenticated) {
            fetchCompanyNames();
        }
    }, [isAuthenticated]);

    const fetchCompanyNames = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await axios.get('/api/company-names', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            setCompanyNames(response.data);
        } catch (error) {
            console.error('Error fetching company names: ', error);
        }
    };

    const handleCompanyNamesChange = (selectedCompanyNames) => {
        onCompanyNamesChange(selectedCompanyNames);
    };

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        return (
            <div
                style={{
                    backgroundColor: '#207edc',
                    color: 'white',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    marginRight: '4px',
                    marginBottom: '4px',
                    display: 'block',
                    width: '100%',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    paddingLeft: '16px'
                }}
            >
                <BankOutlined style={{ marginRight: '8px' }} />
                {label}
                {closable && (
                    <span
                        className={'close-x-button'}
                        style={{ marginLeft: '4px', cursor: 'pointer' }}
                        onClick={onClose}
                    >
                        &times;
                    </span>
                )}
            </div>
        );
    };

    const handleMenuClick = (key) => {
        switch (key) {
            case '1':
                navigate('/agents');
                break;
            case '2':
                navigate('/calls');
                break;
            case '3':
                navigate('/live-calls');
                break;
            case '4':
                navigate('/test-configuration');
                break;
            default:
                break;
        }
    };

    return (
        <Sider breakpoint="lg" collapsedWidth="0" style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'auto' }}>
            <div className="logo-container">
                {/* Optionally, you can add your logo or branding here */}
            </div>
            <div style={{ flexGrow: 1, overflow: 'auto' }}>
                <Menu mode="inline" defaultSelectedKeys={['1']} onClick={(e) => handleMenuClick(e.key)}>
                    <Menu.Item key="1" icon={<UserOutlined />}>
                        Agents
                    </Menu.Item>
                    <Menu.Item key="2" icon={<PhoneOutlined />}>
                        Leads
                    </Menu.Item>
                    <Menu.Item key="3" icon={<CustomerServiceOutlined />}>
                        Live Calls
                    </Menu.Item>
                    <Menu.Item key="4" icon={<SettingOutlined />}>
                        Configuration
                    </Menu.Item>
                </Menu>
                <div className="company-selection-container">
                    <Title style={{ display: 'none', marginLeft: '16px', padding: '8px', color: '#207edc' }} level={5}>
                        <BankOutlined style={{ marginRight: '12px' }} />Properties
                    </Title>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select company names"
                        onChange={handleCompanyNamesChange}
                        tagRender={tagRender}
                    >
                        {companyNames.map((companyName) => (
                            <Option key={companyName} value={companyName}>
                                {companyName}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div style={{ marginBottom: 60 }}>
                <Menu mode="inline" defaultSelectedKeys={['login']}>
                    {!isAuthenticated ? (
                        <Menu.Item key="login" icon={<LoginOutlined />} onClick={() => loginWithRedirect()}>
                            Login
                        </Menu.Item>
                    ) : (
                        <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={() => logout({ returnTo: window.location.origin })}>
                            Logout
                        </Menu.Item>
                    )}
                </Menu>
            </div>
        </Sider>
    );
};

export default NavBar;
