import React from 'react';
import { Badge, Progress, Card, Col, Row, Carousel as AntCarousel } from 'antd';
import ScoreBar from './widgets/ScoreBar';
import ScoreBarCollection from './widgets/ScoreBarCollection';
import moment from 'moment';
import RatingBadge from "./widgets/RatingBadge";


const getGradeColor = (score) => {
    if (score >= 90) return 'darkgreen';
    if (score >= 80) return 'green';
    if (score >= 70) return 'gold';
    if (score >= 60) return 'orange';
    return 'darkred';
};

const AgentDetailView = ({ agentSummary }) => {

    const range = [1, 10];
    const totalCalls = agentSummary?.total || 0;

    // Calculate agent grade
    const agentScores = agentSummary?.summaryInfo?.['questions.AgentScore'] || [];
    const agentScoreAvg = agentScores.reduce((sum, score) => sum + score, 0) / agentScores.length || 0;

    const otherScores = Object.values(agentSummary?.summaryInfo || {})
        .flat()
        .filter((score) => !isNaN(score));
    const otherScoresAvg = otherScores.reduce((sum, score) => sum + score, 0) / otherScores.length || 0;

    const agentGrade = (agentScoreAvg + otherScoresAvg) / 2;
    const agentGradePercentage = (agentGrade / range[1]) * 10;

    return (
        <div style={{  display: 'flex', flexDirection: 'column', width: '100%',height: '100%' }}>
            <Row >
                <Col flex={1}>
                    <div className={"detail-header"}>
                        <h1><span>{agentSummary.agentName}</span></h1>
                        <h2>{agentSummary.companyName} - Agent</h2>
                    </div>
                </Col>
            </Row>
        <Row >
            <Col span={3}>
                <Card style={{height: 200, marginRight: 20}}>
                    <RatingBadge
                        label={'Shop Score'}
                        containerDisplay={'flex'}
                        radius={ '100px '}
                        rating={ agentGrade }
                        render={(value)=>agentGradePercentage.toFixed(0)}
                        backgroundColor={'red'}
                        dynamicColor={true}
                        style={{fontSize: '36px', fontWeight: "bold"}}
                    ></RatingBadge>
                </Card>
            </Col>
            <Col span={12}>
            <ScoreBarCollection range={range} axisWidth={2} axisColor="#207edc" numTicks={11} xLabel="ShopScore Agent Metrics">
                {/*<ScoreBar
                    barHeight={35}
                    data={agentSummary?.summaryInfo['questions.AgentHandledObjections']}
                    range={range}
                    metricName={'Objection Handling'}
                />*/}
                <ScoreBar
                    barHeight={20}
                    data={agentSummary?.summaryInfo['questions.AgentDemeanor']}
                    range={range}
                    metricName={'Demeanor'}
                />
                <ScoreBar
                    barHeight={20}
                    data={agentSummary?.summaryInfo['questions.AgentResponsiveToQuestions']}
                    range={range}
                    metricName={'Responsive'}
                />
                <ScoreBar
                    barHeight={20}
                    data={agentSummary?.summaryInfo['questions.AgentSalesSkills']}
                    range={range}
                    metricName={'Sales Skills'}
                />
                <ScoreBar
                    barHeight={20}
                    data={agentSummary?.summaryInfo['questions.AgentScore']}
                    range={range}
                    metricName={'Overall'}
                />
            </ScoreBarCollection>
            </Col>
            <Col span={3}>
                <Card style={{height: 200}}>
                    <RatingBadge
                        radius={ '100px '}
                        rating={ totalCalls }
                        backgroundColor={'white'}
                        color={'black'}
                        style={{fontSize: '36px', fontWeight: "bold"}}
                        label={'Total Leads'}
                        containerDisplay={'flex'}
                    ></RatingBadge>
                </Card>
            </Col>

        </Row>
            <div style={{ flex: 0.75 }}></div>
            <div style={{ display: 'none', position: 'relative', marginTop: 20 }}>
                <AntCarousel
                    autoplay={true}
                    autoplaySpeed={10000}
                    effect={'fade'}
                    dotPosition="top"
                >
                    {agentSummary?.calls.map((call, index) => (
                        <div key={index}>
                            <h3 style={{marginTop: 10}}>{moment(call.start_timestamp).format('MM/DD/YY') + " - " + call.customer_name}</h3>
                            <p style={{fontSite: '16px'}}>{call.questions.AgentSummary + ' ' + call.ai_q8}</p>
                        </div>
                    ))}
                </AntCarousel>
            </div>
        </div>

    );
};

export default AgentDetailView;
