import React, { useEffect, useState, useCallback, useRef } from 'react';
import { io } from 'socket.io-client';
import { List, Checkbox, Form, Input, Typography, Layout, Button, message, Switch } from 'antd';
import { InfoCircleOutlined, AudioOutlined, AudioMutedOutlined, CaretRightOutlined, CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

const { Content } = Layout;
const { Text } = Typography;

const StyledContent = styled.div`
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  height: calc(100vh - 70px - 32px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ControlsContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PanelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;

const PanelHeader = styled.div`
  background-color: #1890ff;
  color: white;
  padding: 8px 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const PanelContent = styled.div`
  height: ${props => props.isOpen ? (props.height || '100px') : '0'};
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;

const ScrollableContent = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 8px;
`;

const TranscriptChunk = styled.div`
  margin-bottom: 2px;
  padding: 4px;
  border-radius: 4px;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }

  &.latest {
    background-color: #e6f7ff;
    border-left: 3px solid #1890ff;
  }
`;

const SpeakerName = styled.span`
  display: inline-block;
  width: 80px;
  font-weight: bold;
  text-transform: capitalize;
`;

const BottomPanelsContainer = styled.div`
  display: flex;
  height: 100%;
`;

const BottomPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  height: 100%;
  &:last-child {
    margin-right: 0;
  }
`;
const FADED_GREEN_BORDER = 'rgba(82, 196, 26, 0.3)'; // Adjust the alpha value (0.3) to make it more or less faded
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 12px;

  .ant-form-item-label {
    text-transform: capitalize;
    font-weight: bold;
    color: #1890ff;
  }

  &.flash-green-input {
    animation: flashGreen 2s ease-out;
  }

  @keyframes flashGreen {
    0% { color: #52c41a; }
    100% { color: black; }
  }

  .ant-input {
    transition: all 0.3s ease;
  }

  &.filled .ant-input {
    border-color: ${FADED_GREEN_BORDER};
  }
`;

const TodoItem = styled(List.Item)`
  padding: 8px 0;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const TodoIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const EmptyCircleIcon = styled.span`
  width: 20px;
  height: 20px;
  border: 2px solid #1890ff;
  border-radius: 50%;
  display: inline-block;
`;

const TodoText = styled.span`
  display: flex;
  align-items: center;
`;

const CallAuditView = () => {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [goals, setGoals] = useState([]);
    const [todos, setTodos] = useState([]);
    const [callData, setCallData] = useState({
        callerId: '',
        callerName: '',
        duration: 0,
    });
    const [formData, setFormData] = useState({});
    const [callCompleted, setCallCompleted] = useState(false);
    const [callSummary, setCallSummary] = useState(null);
    const [agentEvaluation, setAgentEvaluation] = useState(null);
    const [replayCallSid, setReplayCallSid] = useState('CAc7798d12dad4d712af62f933862d39a7');
    const [isReplaying, setIsReplaying] = useState(false);
    const [showCompletedView, setShowCompletedView] = useState(false);
    const [animatedGoals, setAnimatedGoals] = useState(new Set());
    const [animatedFormFields, setAnimatedFormFields] = useState(new Set());
    const [latestNotificationId, setLatestNotificationId] = useState(null);
    const [transcriptChunks, setTranscriptChunks] = useState([]);
    const [isMuted, setIsMuted] = useState(false);
    const [pendingEvents, setPendingEvents] = useState([]);
    const [currentAudioTime, setCurrentAudioTime] = useState(0);

    const audioRef = useRef(null);
    const notificationsEndRef = useRef(null);
    const socketRef = useRef(null);

    const transcriptRef = useRef(null);
    const notificationsRef = useRef(null);
    const formScrollRef = useRef(null);

    useEffect(() => {
        if (transcriptRef.current) {
            transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
        }
        if (notificationsRef.current) {
            notificationsRef.current.scrollTop = notificationsRef.current.scrollHeight;
        }
    }, [transcriptChunks, messages]);

    const scrollToLatestFormField = useCallback((key) => {
        if (formScrollRef.current) {
            const element = formScrollRef.current.querySelector(`[data-key="${key}"]`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, []);

    const toggleMute = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.muted = !isMuted;
            setIsMuted(!isMuted);
        }
    }, [isMuted]);


    const handleTranscriptChunk = useCallback((data) => {
        setTranscriptChunks(prevChunks => {
            const newChunks = [...prevChunks, data];
            //return newChunks.slice(-5); // Keep only the last 5 chunks
            return newChunks;
        });
    }, []);

    const handleNotification = useCallback((message) => {
        const newId = Date.now();
        setMessages(prevMessages => [{...message, id: newId}, ...prevMessages]);
        setLatestNotificationId(newId);
        notificationsEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, []);

    const handleTodo = useCallback((data) => {
        setGoals(prevGoals => {
            let updatedGoals = [...prevGoals];
            const incomingGoals = Array.isArray(data.goals) ? data.goals : [data.goals];

            incomingGoals.forEach(incomingGoal => {
                let goalFound = false;
                for (let i = 0; i < updatedGoals.length; i++) {
                    if (updatedGoals[i].text?.includes(incomingGoal) || incomingGoal?.includes(updatedGoals[i].text)) {
                        updatedGoals[i].completed = true;
                        goalFound = true;
                        break;
                    }
                }
                if (!goalFound) {
                    updatedGoals.push({ text: incomingGoal, completed: true });
                }
            });

            return updatedGoals;
        });
    }, []);

    const handleCallData = useCallback((data) => {
        setCallData(data);
    }, []);

    const handleFormData = useCallback((data) => {
        setFormData(prevData => {
            const updatedData = { ...prevData, ...data.formData };
            const newKeys = Object.keys(data.formData);
            if (newKeys.length > 0) {
                setTimeout(() => scrollToLatestFormField(newKeys[newKeys.length - 1]), 100);
            }
            return updatedData;
        });
    }, [scrollToLatestFormField]);

    const handleCallCompleted = useCallback((data) => {
        setCallCompleted(true);
        setCallSummary(data.summary);
        setAgentEvaluation(data.evaluation);
        setTodos(prevGoals => [
            ...data.todoList.map(item => ({ text: item, completed: false }))
        ]);
    }, []);

    const handlePlanCall = useCallback((data) => {
        if (data.formData) {
            setFormData(prevData => ({...data.formData, ...prevData}));
        }
        if (data.dataToCollect) {
            const emptyForm = data.dataToCollect.reduce((obj, key) => {
                obj[key] = null;
                return obj;
            }, {});
            setFormData(prevData => ({...emptyForm, ...prevData}));
        }
        setGoals(prevGoals => {
            const newGoals = data.goals
                .filter(goal => goal && goal.trim() !== '')
                .map(goal => ({ text: goal.trim(), completed: false }));

            const uniqueNewGoals = newGoals.filter(newGoal =>
                !prevGoals.some(prevGoal => prevGoal.text === newGoal.text)
            );

            return [...prevGoals, ...uniqueNewGoals];
        });
    }, []);

    const handleReplayComplete = useCallback((result) => {
        setIsReplaying(false);
        if (result.success) {
            message.success(`Successfully replayed call ${result.callSid}`);
        } else {
            message.error(`Failed to replay call ${result.callSid}: ${result.error}`);
        }
    }, []);

    const processEvent = useCallback((eventName, data) => {
        switch (eventName) {
            case 'newCall':
                handleNewCall();
                break;
            case 'notification':
                handleNotification(data);
                break;
            case 'todo':
                handleTodo(data);
                break;
            case 'callData':
                handleCallData(data);
                break;
            case 'formData':
                handleFormData(data);
                break;
            case 'callCompleted':
                handleCallCompleted(data);
                break;
            case 'planCall':
                handlePlanCall(data);
                break;
            case 'replayComplete':
                handleReplayComplete(data);
                break;
            case 'transcriptChunk':
                handleTranscriptChunk(data);
                break;
            default:
                console.warn(`Unhandled event type: ${eventName}`);
        }
    }, [handleNotification, handleTodo, handleCallData, handleFormData, handleCallCompleted, handlePlanCall, handleReplayComplete, handleTranscriptChunk]);

    const handleSocketEvent = useCallback((eventName, data) => {
        console.log(`Socket event received: ${eventName}`, data);
        if (data.timestamp !== undefined) {
            setPendingEvents(prev => [...prev, { type: eventName, data, timestamp: data.timestamp }]);
        } else {
            processEvent(eventName, data);
        }
    }, [processEvent]);

    useEffect(() => {
        socketRef.current = io('/call-audit');

        const socketEvents = [
            'notification', 'todo', 'callData', 'formData', 'callCompleted',
            'planCall', 'replayComplete', 'transcriptChunk', 'newCall'
        ];

        socketEvents.forEach(eventName => {
            socketRef.current.on(eventName, (data) => handleSocketEvent(eventName, data));
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [handleSocketEvent]);

    useEffect(() => {
        if (audioRef.current) {
            const handleTimeUpdate = () => {
                setCurrentAudioTime(audioRef.current.currentTime * 1000);
            };
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
            return () => {
                if (audioRef.current)
                    audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            };
        }
    }, []);

    useEffect(() => {
        const processEvents = () => {
            const currentTime = currentAudioTime;
            const eventsToProcess = pendingEvents.filter(event => event.timestamp <= currentTime);
            const remainingEvents = pendingEvents.filter(event => event.timestamp > currentTime);

            eventsToProcess.forEach(event => {
                processEvent(event.type, event.data);
            });

            if (eventsToProcess.length > 0) {
                setPendingEvents(remainingEvents);
            }
        };

        processEvents();
    }, [currentAudioTime, pendingEvents, processEvent]);

    useEffect(() => {
        if (callCompleted) {
            setShowCompletedView(true);
        }
    }, [callCompleted]);

    const resetState = ()=>{
        // Reset state
        setMessages([]);
        setGoals([]);
        setTodos([]);
        setCallData({
            callerId: '',
            callerName: '',
            duration: 0,
        });
        setFormData({});
        setCallCompleted(false);
        setCallSummary(null);
        setAgentEvaluation(null);
        setShowCompletedView(false);
        setAnimatedGoals(new Set());
        setAnimatedFormFields(new Set());
        setTranscriptChunks([]);
        setPendingEvents([]);
        setCurrentAudioTime(0);
    };

    const handleNewCall = () => {
        resetState();
    }

    const handleReplay = useCallback(() => {
        if (socketRef.current && replayCallSid) {
            resetState();

            // Start replay
            setIsReplaying(true);
            socketRef.current.emit('replayCall', replayCallSid);
            message.info(`Requested replay of call ${replayCallSid}`);

            // Start audio stream
            if (audioRef.current) {
                const audioUrl = `/api/audio-stream/${replayCallSid}`;
                console.log(`Starting audio stream from: ${audioUrl}`);
                audioRef.current.src = audioUrl;
                audioRef.current.play().then(() => {
                    console.log('Audio playback started successfully');
                }).catch(error => {
                    console.error('Error playing audio:', error);
                    message.error('Failed to play audio. Please try again.');
                });
            }
        } else {
            message.warning('Please enter a valid Call SID');
        }
    }, [replayCallSid]);

    const toggleView = useCallback((checked) => {
        setShowCompletedView(checked);
    }, []);



    const [panelStates, setPanelStates] = useState({
        transcript: true,
        notifications: true,
        callSummary: true,
        goalsAndData: true,
    });

    const togglePanel = (panel) => {
        setPanelStates(prev => ({ ...prev, [panel]: !prev[panel] }));
    };

    return (
        <StyledContent>
            <ControlsContainer>
                <div>
                    <Input
                        placeholder="Enter Call SID to replay"
                        value={replayCallSid}
                        onChange={(e) => setReplayCallSid(e.target.value)}
                        style={{ width: 200, marginRight: 8 }}
                        disabled={isReplaying}
                    />
                    <Button
                        onClick={handleReplay}
                        disabled={isReplaying || !replayCallSid}
                        style={{ marginRight: 8 }}
                    >
                        {isReplaying ? 'Replaying...' : 'Replay Call'}
                    </Button>
                    <Button
                        onClick={toggleMute}
                        icon={isMuted ? <AudioMutedOutlined /> : <AudioOutlined />}
                        disabled={!isReplaying}
                    >
                        {isMuted ? 'Unmute' : 'Mute'}
                    </Button>
                </div>
                {callCompleted && (
                    <div>
                        <Text>In-progress</Text>
                        <Switch
                            checked={showCompletedView}
                            onChange={toggleView}
                            style={{ margin: '0 8px' }}
                        />
                        <Text>Completed</Text>
                    </div>
                )}
            </ControlsContainer>

            <PanelsContainer>
                {(!callCompleted || !showCompletedView) && (
                    <>


                        <Panel>
                            <PanelHeader onClick={() => togglePanel('notifications')}>
                                Notifications
                                <CaretRightOutlined rotate={panelStates.notifications ? 90 : 0} />
                            </PanelHeader>
                            <PanelContent isOpen={panelStates.notifications} height="125px">
                                <ScrollableContent>
                                    <TransitionGroup>
                                        {messages.map((message) => (
                                            <CSSTransition
                                                key={message.id}
                                                timeout={1000}
                                                classNames="notification"
                                            >
                                                <List.Item
                                                    className={`custom-list-item notification-item ${message.id === latestNotificationId ? 'notification-new' : ''}`}
                                                    style={{ display: 'flex', alignItems: 'center', padding: '2px 0', marginBottom: '4px', marginLeft: '6px' }}
                                                >
                                                    <InfoCircleOutlined style={{ marginRight: '8px' }} />
                                                    <div>{message.text}</div>
                                                </List.Item>
                                            </CSSTransition>
                                        ))}
                                    </TransitionGroup>
                                </ScrollableContent>
                            </PanelContent>
                        </Panel>
                    </>
                )}

                {callCompleted && showCompletedView && (
                    <Panel>
                        <PanelHeader onClick={() => togglePanel('callSummary')}>
                            Call Summary
                            <CaretRightOutlined rotate={panelStates.callSummary ? 90 : 0} />
                        </PanelHeader>
                        <PanelContent isOpen={panelStates.callSummary} height="200px">
                            <ScrollableContent>
                                <Text strong>Call Recap</Text>
                                <p>{callSummary}</p>
                                <Text strong>Performance Tips</Text>
                                <p>{agentEvaluation}</p>
                            </ScrollableContent>
                        </PanelContent>
                    </Panel>
                )}

                <Panel style={{ flexGrow: panelStates.goalsAndData ? 1 : 0 }}>
                    <PanelHeader onClick={() => togglePanel('goalsAndData')}>
                        {(!callCompleted || !showCompletedView) ? 'Goals and Call Data' : 'Follow-Ups and Call Data'}
                        <CaretRightOutlined rotate={panelStates.goalsAndData ? 90 : 0} />
                    </PanelHeader>
                    <PanelContent style={{ flexGrow: panelStates.goalsAndData ? 1 : 0, display: panelStates.goalsAndData ? 'flex' : 'none', flexDirection: 'column' }}>
                        <BottomPanelsContainer>
                            <BottomPanel>
                                <ScrollableContent>
                                    <TransitionGroup>
                                        {((!callCompleted || !showCompletedView) ? goals : todos).map((item, index) => (
                                            <CSSTransition
                                                key={item.text}
                                                timeout={1500}
                                                classNames="item"
                                                onEntered={() => {
                                                    if (item.completed && !animatedGoals.has(item.text)) {
                                                        setAnimatedGoals(prev => new Set(prev).add(item.text));
                                                    }
                                                }}
                                            >
                                                <TodoItem
                                                    className={`custom-list-item ${item.completed && !animatedGoals.has(item.text) ? 'flash-green' : ''}`}
                                                >
                                                    <TodoIcon>
                                                        {item.completed ? (
                                                            <CheckCircleFilled style={{ color: '#52c41a' }} />
                                                        ) : (
                                                            <EmptyCircleIcon />
                                                        )}
                                                    </TodoIcon>
                                                    <TodoText>{item.text}</TodoText>
                                                </TodoItem>
                                            </CSSTransition>
                                        ))}
                                    </TransitionGroup>
                                </ScrollableContent>
                            </BottomPanel>
                            <BottomPanel>
                                <ScrollableContent ref={formScrollRef}>
                                    <Form layout="vertical">
                                        <TransitionGroup>
                                            {Object.entries(formData).map(([key, value]) => (
                                                <CSSTransition
                                                    key={key}
                                                    timeout={1500}
                                                    classNames="item"
                                                    onEntered={() => {
                                                        if (value && !animatedFormFields.has(key)) {
                                                            setAnimatedFormFields(prev => new Set(prev).add(key));
                                                        }
                                                    }}
                                                >
                                                    <StyledFormItem
                                                        label={key}
                                                        className={`${value ? 'filled' : ''} ${value && !animatedFormFields.has(key) ? 'flash-green-input' : ''}`}
                                                        data-key={key}
                                                    >
                                                        <Input
                                                            value={value}
                                                            readOnly
                                                            style={{
                                                                transition: 'all 0.3s ease',
                                                                borderColor: value ? FADED_GREEN_BORDER : '#d9d9d9',
                                                                borderWidth: '1px',
                                                                boxShadow: 'none'
                                                            }}
                                                        />
                                                    </StyledFormItem>
                                                </CSSTransition>
                                            ))}
                                        </TransitionGroup>
                                    </Form>
                                </ScrollableContent>
                            </BottomPanel>
                        </BottomPanelsContainer>
                    </PanelContent>
                </Panel>
                {(!callCompleted || !showCompletedView) && (

                <Panel>
                    <PanelHeader onClick={() => togglePanel('transcript')}>
                        Live Transcript
                        <CaretRightOutlined rotate={panelStates.transcript ? 90 : 0} />
                    </PanelHeader>
                    <PanelContent isOpen={panelStates.transcript} height="125px">
                        <ScrollableContent ref={transcriptRef}>
                            <TransitionGroup>
                                {transcriptChunks.map((chunk, index) => (
                                    <CSSTransition
                                        key={index}
                                        timeout={500}
                                        classNames="fade"
                                    >
                                        <TranscriptChunk className={index === transcriptChunks.length - 1 ? 'latest' : ''}>
                                            <SpeakerName style={{ color: chunk.speaker === 'agent' ? 'blue' : 'green' }}>
                                                {chunk.speaker}:
                                            </SpeakerName>
                                            {chunk.text}
                                        </TranscriptChunk>
                                    </CSSTransition>
                                ))}
                            </TransitionGroup>
                        </ScrollableContent>
                    </PanelContent>
                </Panel>)}
            </PanelsContainer>



            <audio ref={audioRef} style={{ display: 'none' }} />
        </StyledContent>
    );
};

export default CallAuditView;
