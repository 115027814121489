import React, { useState, useEffect, useRef } from 'react';
import { Table, DatePicker, Input, Space, Select, Button, Tag } from 'antd';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { CheckOutlined, CheckCircleFilled, CloseOutlined, RightCircleOutlined, MoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSharedData } from "./contexts/SharedDataContext";

const { RangePicker } = DatePicker;
const { Option } = Select;

const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const DataView = (props) => {

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [data, setData] = useState(props.data ? props.data : []);
    const [filteredData, setFilteredData] = useState(data);
    const [lastRecordId, setLastRecordId] = useState(null);
    const [pagination, setPagination] = useState({ pageSize: 100, current: 1, total: 0 });
    const [sorter, setSorter] = useState(null);
    const [filter, setFilter] = useState({});
    const [loading, setLoading] = useState(false);
    const { searchQuery, setSearchQuery, dateRange, setDateRange } = useSharedData();
    const [dateRangeMode, setDateRangeMode] = useState('preset');
    const [hasMore, setHasMore] = useState(true);
    const [currentQuery, setCurrentQuery] = useState({});
    const [viewColumns, setViewColumns] = useState([]);
    const [currentView, setCurrentView] = useState(props.view ); // 'Calls' or 'Agents'

    const initialRenderRef = useRef(true);

    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            fetchColumnConfig();
        }
    }, []);

    useEffect( () => {
        console.log('Prop View changed');
        setCurrentView(props.view);
    }, [props.view]);

    useEffect( () => {
        console.log('Current View changed');
        fetchColumnConfig();
    }, [currentView]);

    const fetchColumnConfig = async (view = currentView) => {
        const accessToken = await getAccessTokenSilently({
            audience: "https://audience.auth0.leadlensai.com",
            scope: "openid profile email",
        });
        try {
            const response = await axios.get('/api/config/tableview', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    view: view,
                    user: user.sub
                }
            });
            const columnConfig = response.data;
            console.log('Fetched Column Config:', columnConfig); // Log the column config
            const formattedColumns = columnConfig.map(col => ({
                ...col,
                dataIndex: col.dataIndex || col.key, // Ensure dataIndex is set correctly
                render: createRenderer(col.rendererType, col.key)
            }));
            setViewColumns(formattedColumns);
        } catch (error) {
            console.error('Error fetching column configuration:', error);
        }
    };

    const createRenderer = (rendererType, key) => {
        const customRenderers = {
            ratingBadge: (value) => ratingBadge(value),
            secondaryRatingBadge:  (value) => ratingBadge(value,true),
            timestamp: (value) => moment.unix(value['_seconds']).format('MM/DD/YY hh:mm A'),
            agentLink: (text, record) => (<a onClick={(e) => onAgentClick(e, record.questions?.AgentName)}>{record.questions?.AgentName}</a>),
            checkmark: (text) => { return text === true || text === 'Yes' ? (<CheckOutlined style={{ color: 'darkgrey', fontSize: '16px' }} />) : null;}
        };

        const evalRenderer = (record) => {
            if (!key.includes('.')) {
                return record[key];
            }

            const keys = key.split('.');
            let value = record;

            for (const k of keys) {
                if (value === null || value === undefined) {
                    return null;
                }
                value = value[k];
            }

            return value;
        };

        const renderer = customRenderers[rendererType];

        if (renderer) {
            return (_, record) => {
                const value = evalRenderer(record);
                return value !== null ? renderer(value,record) : '';
            };
        }

        return (_, record) => {
            const value = evalRenderer(record);
            return sanitizeValue(value);
        };
    };

    const filterData = () => {
        if (searchQuery.length >= 3) {
           const filtered = data.filter((record) => {
                const values = Object.values(record).map((value) =>
                    typeof value === 'string' ? value.toLowerCase() : ''
                );
                return values.some((value) =>
                    value.includes(searchQuery.toLowerCase())
                );
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    };

    useEffect(() => {
        filterData();
    }, [searchQuery, data]);

    useEffect(() => {
        // Clear existing filters and refresh the table when selectedCompanyNames change
        console.log('Daterange :' + JSON.stringify(dateRange));
        setFilter({});
        setSearchQuery('');
        fetchData({
            pagination,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            dateRange: dateRange,
            filter: {},
        }, true);
    }, [props.selectedCompanyNames]);

    const presetRanges = [
        { label: 'Last Hour', value: 'lastHour' },
        { label: 'Last 4 Hours', value: 'last4Hours' },
        { label: 'Last 12 Hours', value: 'last12Hours' },
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'This Week', value: 'thisWeek' },
        { label: 'Last Week', value: 'lastWeek' },
        { label: 'Last 7 Days', value: 'last7Days' },
        { label: 'This Month', value: 'thisMonth' },
        { label: 'Last Month', value: 'lastMonth' },
        { label: 'Last 30 Days', value: 'last30Days' },
        { label: 'Last 3 Months', value: 'last3Months' },
        { label: 'Last 6 Months', value: 'last6Months' },
        { label: 'Last 12 Months', value: 'last12Months' },
        { label: 'This Year', value: 'thisYear' },
    ];

    const onDateRangeChange = (dates) => {
        setDateRange(dates);
        fetchData({
            pagination,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            dateRange: dates,
            filter: filter,
        },true);
    };

    const onPresetRangeChange = (value) => {
        if (value === 'custom') {
            setDateRangeMode('custom');
        } else {
            const { startDate, endDate } = calculatePresetRange(value);
            setDateRange([startDate, endDate]);
            fetchData({
                pagination,
                sortField: sorter?.field,
                sortOrder: sorter?.order,
                dateRange: [startDate, endDate],
                filter: filter,
            }, true);
        }
    };

    const calculatePresetRange = (presetRange) => {
        const now = moment();
        let startDate, endDate;

        switch (presetRange) {
            case 'lastHour':
                startDate = now.clone().subtract(1, 'hour');
                endDate = now;
                break;
            case 'last4Hours':
                startDate = now.clone().subtract(4, 'hours');
                endDate = now;
                break;
            case 'last12Hours':
                startDate = now.clone().subtract(12, 'hours');
                endDate = now;
                break;
            case 'today':
                startDate = now.clone().startOf('day');
                endDate = now.clone().endOf('day');
                break;
            case 'yesterday':
                startDate = now.clone().subtract(1, 'day').startOf('day');
                endDate = now.clone().subtract(1, 'day').endOf('day');
                break;
            case 'thisWeek':
                startDate = now.clone().startOf('week');
                endDate = now.clone().endOf('week');
                break;
            case 'lastWeek':
                startDate = now.clone().subtract(1, 'week').startOf('week');
                endDate = now.clone().subtract(1, 'week').endOf('week');
                break;
            case 'last7Days':
                startDate = now.clone().subtract(7, 'days');
                endDate = now;
                break;
            case 'thisMonth':
                startDate = now.clone().startOf('month');
                endDate = now.clone().endOf('month');
                break;
            case 'lastMonth':
                startDate = now.clone().subtract(1, 'month').startOf('month');
                endDate = now.clone().subtract(1, 'month').endOf('month');
                break;
            case 'last30Days':
                startDate = now.clone().subtract(30, 'days');
                endDate = now;
                break;
            case 'last3Months':
                startDate = now.clone().subtract(3, 'months');
                endDate = now;
                break;
            case 'last6Months':
                startDate = now.clone().subtract(6, 'months');
                endDate = now;
                break;
            case 'last12Months':
                startDate = now.clone().subtract(12, 'months');
                endDate = now;
                break;
            case 'thisYear':
                startDate = now.clone().startOf('year');
                endDate = now.clone().endOf('year');
                break;
            default:
                startDate = now.clone().startOf('day');
                endDate = now.clone().endOf('day');
        }

        return { startDate, endDate };
    };


    const [selectedCall, setSelectedCall] = useState(null);

    const onRowSelection = (selectedRowKeys, selectedRows) => {
        setSelectedCall(selectedRows[0]); // Assuming single selection
    };

    const fetchData = async (params = {}, isNewQuery = true) => {
        console.log(`Fetching data new = ${isNewQuery}  more = ${hasMore}  loading = ${loading}`);
        if(loading)
            return;
        const accessToken = await getAccessTokenSilently({
            audience: "https://audience.auth0.leadlensai.com",
            scope: "openid profile email",
        });
        try {
            if (!isNewQuery && !hasMore) {
                return;
            }
            if (!isNewQuery) {
                params = {
                    ...currentQuery,
                    lastRecordId: lastRecordId
                };
            } else {
                setLastRecordId(null);
            }


            console.log("New " + isNewQuery + " - Fetching  data with params: " + JSON.stringify(params) + " lastRecord " + lastRecordId);
            setLoading(true);
            const response = await axios.get('/api/data', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    ...params,
                    lastRecordId : isNewQuery ? null : lastRecordId,
                    pageSize: params.pageSize,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                    startDate: params.dateRange ? params.dateRange[0].format('YYYY-MM-DD HH:mm:ss') : undefined,
                    endDate: params.dateRange ? params.dateRange[1].format('YYYY-MM-DD HH:mm:ss') : undefined,
                    filter: JSON.stringify(params.filter || {}),
                    companyNames : props.selectedCompanyNames,
                }
            });
            setHasMore(response.data.hasMore);
            if (response.data.data) {
                response.data.data = response.data.data.map(item => {
                    if (!item.hasOwnProperty('questions')) {
                        item.questions = {};
                    }
                    return item;
                });
            }

            if(response.data.data.length > 0 && response.data.data[response.data.data.length - 1].id != lastRecordId)
                setLastRecordId(response.data.data[response.data.data.length - 1].id);

            if(isNewQuery) {
                setData(response.data.data);
                setCurrentQuery(params);
            }
            else {
                let newData = data.concat(response.data.data);
                console.log("New Table Length: " + newData.length);
                setData(newData);
            }
        } catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            setLoading(false);
        }
    };

   /* const fetchDataPaged = async (params = {}) => {
        setLoading(true);
        const accessToken = await getAccessTokenSilently({
            audience: "https://audience.auth0.leadlensai.com",
            scope: "openid profile email",
        });
        try {
            console.log("Fetching data with params: " + JSON.stringify(params));
            const response = await axios.get('/api/data', {
                headers: {
                    Authorization: `Bearer ${accessToken}`, // Include the token here
                },
                params: {
                    ...params,
                    page: params.pagination.current,
                    pageSize: params.pagination.pageSize,
                    sortField: params.sortField,
                    sortOrder: params.sortOrder,
                    startDate: params.dateRange ? params.dateRange[0].format('YYYY-MM-DD HH:mm:ss') : undefined,
                    endDate: params.dateRange ? params.dateRange[1].format('YYYY-MM-DD HH:mm:ss') : undefined,
                }
            });

            setData(response.data.data);
            setPagination(prevPagination => ({
                ...prevPagination,
                current: params.pagination.current,
                pageSize: params.pagination.pageSize,
                // Ensure this total is correctly returned from your server considering any filters/sorting
                total: response.data.total,
            }));

        } catch (error) {
            console.error('Error fetching data: ', error);
        } finally {
            setLoading(false);
        }
    };*/


    useEffect(() => {
        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);
        return () => {
            window.removeEventListener('resize', updateTableHeight);
        };
    }, []);

    useEffect(() => {
        if (props.triggerHeightUpdate) {
            updateTableHeight(props.triggerHeightUpdate);
        }
    }, [props.triggerHeightUpdate]);

    const handleTableChange = (pagination, filters, sorter) => {
        console.log('From table change');
        setFilter(filters);
        setSorter(sorter);
       /* fetchData({
            pagination,
            sortField: sorter.field,
            sortOrder: sorter.order,
            dateRange: dateRange,
        });*/
    };


    useEffect(() => {
        if (initialRenderRef.current) {
            initialRenderRef.current = false;
            fetchData({
                pagination,
                sortField: sorter?.field,
                sortOrder: sorter?.order,
                dateRange: dateRange,
                filter: filter
            },true);
        }
    }, []);

    const onAgentClick = (e, agentName) => {
        e.stopPropagation();
        setCurrentView('Agents');
        setData([]);
        //fetchColumnConfig('Agents');
       // props.onSelectAgent(agentName);
        setFilter({ 'questions.AgentName': agentName });
        const { startDate, endDate } = calculatePresetRange("last30Days");
        setDateRange([startDate, endDate]);
        fetchData({
            pagination,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            dateRange: [startDate, endDate],
            filter: { 'questions.AgentName': agentName },
        }, true);
        props.onSelectAgent(agentName);
    };

    const ratingBadge = (rating, colorFontOnly = false) => {
        const displayRating = (rating === null || isNaN(rating)) ? 'N/A' : rating;
        return (
            <div style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: colorFontOnly ? "inherit" : getRatingColor(rating),
                color: colorFontOnly ? getRatingColor(rating) : 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                borderColor : getRatingColor(rating),
                borderStyle: "solid",
                borderWidth: "2px",
                margin: 'auto'
            }}>
                {displayRating}
            </div>
        );
    };

    // Adjusted rating column definition with failsafe for null or non-numeric ratings
    const ratingColumn = {
        title: 'Rating',
        dataIndex: 'ai_q2',
        key: 'rating',
        width: 77,
        render: (rating) => {
            // Determine display rating - if rating is not a number, display a dash or any placeholder you prefer
            return ratingBadge(rating);
        },
    };

    const shopRatingColumn = {
        title: 'Shop',
        key: 'questions.AgentScore',
        width: 77,
        render: (text, record) => {
            return ratingBadge(record.questions?.AgentScore);
        },
    };

    const agentRatingColumn = (title,key) => {
       return  {
            title: title,
            key: 'questions.' + key,
            width: 68,
            render: (text, record) => {
            return record?.questions[key] ? ratingBadge(record.questions[key], true) : '';
        },
        };
    }

    let columnsDefault = [
        ratingColumn,
        { title: 'Caller Name', dataIndex: 'formatted_customer_name', key: 'formatted_customer_name',  width: 150, sorter: false, isTagFilterable: false  },
        { title: 'Company', dataIndex: 'company_name', key: 'company_name', sorter: false, width: 150, isTagFilterable: true },
        { title: 'Type', dataIndex: 'ai_q1', key: 'a1_q1', sorter: false, width: 110, isTagFilterable: true  },
        { title: 'Source', dataIndex: 'source', key: 'source', sorter: false, width: 150, isTagFilterable: true  },
        {
            title: 'F/U',
            key: 'ai_q3',
            dataIndex: 'ai_q3',
            width: 70,
            render: (text) => {
                return text === true || text === 'Yes' ? (
                    <CheckOutlined style={{ color: 'darkgrey', fontSize: '16px' }} />
                ) : null;
            },
        },
        { title: 'Floorplan', dataIndex: 'ai_q4', key: 'a1_q4', sorter: false, width: 100, isTagFilterable: true  },
        { title: 'Move-In', dataIndex: 'ai_q5', key: 'a1_q5', sorter: false, width: 95, isTagFilterable: true  },
        { title: 'Affordable?', dataIndex: 'ai_q7', key: 'a1_q7', sorter: false, width: 110, isTagFilterable: true  },
        { title: 'Phone Number', dataIndex: 'formatted_customer_phone_number', key: 'formatted_customer_phone_number', sorter: false, width: 135, isTagFilterable: false  },
        {
            title: 'Time of Call',
            dataIndex: 'start_timestamp',
            key: 'start_timestamp',
            width: 140,
            render: (timestamp) => {
                // Format the date using moment.js
                return moment.unix(timestamp['_seconds']).format('MM/DD/YY hh:mm A');
            },
        },
        shopRatingColumn,
        {
            title: 'Agent', key: 'questions.AgentName', sorter: false,width: 150,
            render: (text, record) => <a onClick={
                (e) => onAgentClick(e, record.questions?.AgentName)}>{record.questions?.AgentName}</a>,
        },
        // Define other columns as needed
    ];

    let columnsAgent = [
        shopRatingColumn,
        agentRatingColumn('Demeanor','AgentDemeanor'),
        agentRatingColumn('Responsive','AgentResponsiveToQuestions'),
        agentRatingColumn('Sales Skill','AgentSalesSkills'),
        {
            title: 'Agent', key: 'questions.AgentName', sorter: false, width: 140,
            render: (text, record) => <a onClick={
                (e) => onAgentClick(e, record.questions?.AgentName)}>{record.questions?.AgentName}</a>,
        },
        { title: 'Property', dataIndex: 'company_name', key: 'company_name', sorter: false, width: 135, isTagFilterable: true },
        { title: 'Call Details', dataIndex: 'questions.AgentSummary', key: 'questions.AgentSummary', width: 400, sorter: false,  render: (text, record) => record.questions.AgentSummary + ' ' + record.ai_q8},
        { title: 'Caller Name', dataIndex: 'formatted_customer_name', key: 'formatted_customer_name', width: 115, sorter: false, isTagFilterable: false  },
        ratingColumn,
        {
            title: 'Time of Call',
            dataIndex: 'start_timestamp',
            key: 'start_timestamp',
            width:112,
            render: (timestamp) => {
                // Format the date using moment.js
                return moment.unix(timestamp['_seconds']).format('MM/DD/YY hh:mm A');
            },
        },
    ];

     let columns = filter['questions.AgentName'] ? columnsAgent : columnsDefault;

    const getColumnHeaderByField = (field) => {
        const column = columns.find((col) => col.key === field || col.dataIndex === field);
        return column ? column.title : field;
    };

    const sanitizeValue = (value) => value === "N/A" || value === "Unknown" ? "" : value;
    columns = columns.map(col => ({
        ...col,
        render: col.render ? col.render : (text, record) => (
            col.isTagFilterable ? (
                <span onClick={() => handleTagFilter(record, col.dataIndex)}>
                {sanitizeValue(text)}
            </span>
            ) : (
                sanitizeValue(text)
            )
        ),
    }));

    const handleTagFilter = (record, dataIndex) => {
        const value = record[dataIndex];
        setFilter({ ...filter, [dataIndex]: value });
        fetchData({
            pagination,
            sortField: sorter?.field,
            sortOrder: sorter?.order,
            dateRange: dateRange,
            filter: { ...filter, [dataIndex]: value },
        }, true);
    };

    // Adjusted utility function to calculate color based on rating
    const getRatingColor = (rating) => {
        // Check if rating is null or not a numeric value
        if (rating === null || isNaN(rating)) {
            return 'gray'; // Return gray color for invalid ratings
        }
        const hue = ((rating - 1) * 12); // Calculate hue from red to green (1 = 0, 10 = 108)
        return `hsl(${hue}, 90%, 30%)`; // HSL color representation for valid ratings
    };

    const [tableBodyHeight, setTableBodyHeight] = useState(Math.max(window.innerHeight * 0.5, 200)); // Default height

    const updateTableHeight = (newHeight) => {
        // Calculate the available height for the table body, reserving space for pagination
        const spaceReservedForPagination = 200; // Adjust based on your actual pagination control height
        const calculatedHeight = (newHeight || Math.max(window.innerHeight * 0.5, 200)) - spaceReservedForPagination;
        setTableBodyHeight(calculatedHeight);
    };


    const scrollListenerRef = useRef(null); // Ref to store the scroll event listener

    useEffect(() => {
        const handleScroll = (e) => {
            const { scrollTop, clientHeight, scrollHeight } = e.target;
            const threshold = 50;

            if (!loading && hasMore && (scrollHeight - scrollTop - clientHeight < threshold)) {
               fetchData({},false);
            }
        };

        // Debounced version of handleScroll
        const debouncedHandleScroll = debounce(handleScroll, 200);

        // Function to attach the scroll event listener
        const attachScrollListener = () => {
            const scrollableElement = document.querySelector('.ant-table-body');
            if (scrollableElement && !scrollListenerRef.current) {
                scrollListenerRef.current = debouncedHandleScroll;
                scrollableElement.addEventListener('scroll', scrollListenerRef.current);
            }
        };

        // Function to detach the scroll event listener
        const detachScrollListener = () => {
            const scrollableElement = document.querySelector('.ant-table-body');
            if (scrollableElement && scrollListenerRef.current) {
                scrollableElement.removeEventListener('scroll', scrollListenerRef.current);
                scrollListenerRef.current = null; // Reset the ref after removing the listener
            }
        };

        attachScrollListener();

        // Cleanup function to detach the event listener
        return () => {
            detachScrollListener();
        };
    }, [loading, hasMore]); // Add dependencies that might change and require re-attaching the event listener

    const FilterTag = ({ field, value, onRemove }) => (
        <Tag closable onClose={onRemove}>
            {field} = "{value}"
        </Tag>
    );


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
                <Space>
                    {Object.entries(filter).map(([field, value]) => (
                        <FilterTag
                            key={field}
                            field={getColumnHeaderByField(field)}
                            value={value}
                            onRemove={() => {
                                const newFilter = { ...filter };
                                delete newFilter[field];
                                setFilter(newFilter);
                                fetchData({
                                    pagination,
                                    sortField: sorter?.field,
                                    sortOrder: sorter?.order,
                                    dateRange: dateRange,
                                    filter: newFilter
                                }, true);
                            }}
                        />
                    ))}
                    <Input.Search
                        placeholder="Search these leads"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{ width: 300 }}
                    />
                    {dateRangeMode === 'preset' ? (
                        <Select
                            placeholder={'Select date range'}
                            style={{ width: 200 }}
                            onChange={onPresetRangeChange}
                        >
                            {presetRanges.map(range => (
                                <Option key={range.value} value={range.value}>{range.label}</Option>
                            ))}
                            <Option value="custom">Custom</Option>
                        </Select>
                    ) : (
                        <Space>
                            <RangePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={onDateRangeChange}
                            />
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => setDateRangeMode('preset')}
                            />
                        </Space>
                    )} <Button
                    icon={<RightCircleOutlined />}
                    onClick={() =>   fetchData({},false)}>{data.length} Leads { hasMore ? '(load more)' : ''}</Button>
                </Space>
            </div>
            <Table
                style={{ flexGrow: 1 }}
                columns={viewColumns}
                rowKey={record => record.id}
                dataSource={filteredData}
                pagination={false}
                loading={loading}
                onChange={handleTableChange}
                scroll={{ y: tableBodyHeight }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            props.onRowClick(record);
                        },
                    };
                }}
            />
        </>
    );
};

export default DataView;
