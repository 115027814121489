import React from 'react';

const ScoreBarCollection = ({
                                children,
                                range,
                                axisWidth = 1,
                                axisColor = '#000',
                                numTicks = 10,
                                xLabel = '',
                                marginTop = 10,
                            }) => {
    const [min, max] = range;
    const tickInterval = (max - min) / (numTicks - 1);

    return (
        <div className="score-bar-collection" style={{ position: 'relative', marginTop: '20px', marginRight: '20px' }}>
            <div
                className="score-bar-collection__axis-y"
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    marginBottom: 20,
                    bottom: axisWidth / 2,
                    borderLeft: `${axisWidth}px solid ${axisColor}`,
                }}
            />
            <div className="score-bar-collection__bars">{children}</div>
            <div
                className="score-bar-collection__axis-x"
                style={{
                    borderTop: `${axisWidth}px solid ${axisColor}`,
                    marginTop: `${marginTop}px`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                {Array.from({ length: numTicks }, (_, index) => (
                    <div
                        key={index}
                        style={{
                            width: '1px',
                            height: '10px',
                            backgroundColor: axisColor,
                            marginTop: '-5px',
                        }}
                    />
                ))}
            </div>
            <div
                className="score-bar-collection__label"
                style={{ textAlign: 'center', marginTop: '10px', color: axisColor }}
            >
                {xLabel}
            </div>
        </div>
    );
};

export default ScoreBarCollection;
