import React, { useState, useEffect } from 'react';
import { Descriptions, Input, Button, Slider, Typography, Select } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import RatingBadge from '../widgets/RatingBadge';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const { Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const EditableDescription = ({ item, label, onSave, inputType = 'text', validator = null, ratingBadgeProps = null, field, options: propOptions }) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(item);
    const [options, setOptions] = useState(propOptions || []);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        setValue(item);
    }, [item]);

    useEffect(() => {
        if (inputType === 'dropdown' && !propOptions) {
            fetchDropdownOptions();
        }
    }, [inputType, propOptions]);

    const fetchDropdownOptions = async () => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: 'https://audience.auth0.leadlensai.com',
                scope: 'openid profile email',
            });

            const response = await axios.get(`/api/dropdown-options?field=${field}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            setOptions(response.data);
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
        }
    };

    const handleEdit = () => {
        setEditing(true);
    };

    const handleSave = () => {
        if (typeof validator === 'string') {
            if (!validateField(value, validator)) {
                return;
            }
        } else if (typeof validator === 'function') {
            if (!validator(value)) {
                return;
            }
        }
        console.log("Saving value " + value);
        onSave(value);
        setEditing(false);
    };

    const handleCancel = () => {
        setValue(item);
        setEditing(false);
    };
    const validateField = (value, type) => {
        switch (type) {
            case 'phone':
                // Perform phone number validation
                return /^\d{10}$/.test(value);
            case 'email':
                // Perform email validation
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
            case 'integer':
                // Perform integer validation
                return Number.isInteger(Number(value));
            case 'number':
                // Perform number validation
                return !isNaN(Number(value));
            default:
                return true;
        }
    };

    const getRatingColor = (rating) => {
        const hue = ((rating - 1) * 12); // Calculate hue from red to green (1 = 0, 10 = 108)
        return `hsl(${hue}, 90%, 30%)`; // HSL color representation for valid ratings
    };

    const renderInput = () => {
        switch (inputType) {
            case 'slider':
                return (
                    <>
                        <RatingBadge rating={Math.round(value)} {...ratingBadgeProps} />
                        <Slider
                            min={1}
                            max={10}
                            step={0.1}
                            value={value}
                            onChange={setValue}
                            onAfterChange={()=>{}}
                            style={{ flexGrow: 1, marginLeft: '20px', marginRight: '20px' }}
                            trackStyle={{ backgroundColor: getRatingColor(value) }}
                            handleStyle={{
                                borderColor: getRatingColor(value),
                                boxShadow: `0 0 0 4px ${getRatingColor(value)}40`, // Add box shadow with opacity
                            }}
                        />
                    </>
                );
            case 'paragraph':
                return (
                    <TextArea
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onBlur={()=>{}}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                );
            case 'dropdown':
                return (
                    <Select value={value} onChange={setValue} style={{ width: '100%' }}>
                        {options.map((option) => (
                            <Option key={option} value={option}>
                                {option}
                            </Option>
                        ))}
                    </Select>
                );
            default:
                return (
                    <Input
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onPressEnter={handleSave}
                        onBlur={() => setEditing(false)}
                    />
                );
        }
    };

    const renderContent = () => {
        switch (inputType) {
            case 'slider':
                return <RatingBadge rating={Math.round(value)} {...ratingBadgeProps} />;
            case 'paragraph':
                return (
                    <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
                        {value}
                    </Paragraph>
                );
            default:
                return value;
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {editing ? (
                <>
                    {renderInput()}
                    <Button
                        type="link"
                        icon={<CheckOutlined />}
                        onClick={handleSave}
                        style={{ marginLeft: '2px', fontSize: '10px' }}
                    />
                    <Button
                        type="link"
                        icon={<CloseOutlined />}
                        onClick={handleCancel}
                        style={{ marginLeft: '0px', fontSize: '10px' }}
                    />
                </>
            ) : (
                <>
                    <div style={{ flexGrow: 1 }}>{renderContent()}</div>
                    <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={handleEdit}
                        style={{ marginLeft: '8px' }}
                    />
                </>
            )}
        </div>
    );
};
export default EditableDescription;
