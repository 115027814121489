import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

const GradientBar = ({ gradientStyle, barHeight }) => {
    return (
        <div
            className="score-bar__gradient"
            style={{
                width: '100%',
                height: `${barHeight}px`,
                borderRadius: '0 20px 20px 0',
                background: gradientStyle,
            }}
        />
    );
};

const ScoreBar = ({ data = [], range, metricName, barHeight = 20, labelPosition = 'bottom' }) => {
    const [min, max] = range;
    const filteredData = data.filter((value) => typeof value === 'number' && !isNaN(value));
    const totalValues = filteredData.length;

    const averageValue = totalValues > 0 ? filteredData.reduce((acc, val) => acc + val, 0) / totalValues : 0;
    const barWidth = (averageValue - min) / (max - min) * 100;
    const chartRef = useRef(null);

    let gradientStyle;

    if (totalValues === 1) {
        const hue = ((averageValue - 1) * 12); // Calculate hue from red to green (1 = 0, 10 = 108)
        const color = `hsl(${hue}, 90%, 30%)`; // HSL color representation for valid ratings
        gradientStyle = color;
    } else {
        const values = filteredData.filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a - b);
        const gradientStops = values.map((value, index, arr) => {
            const count = filteredData.filter((v) => v === value).length;
            const percentage = (count / totalValues) * 100;
            const hue = ((value - 1) * 12); // Calculate hue from red to green (1 = 0, 10 = 108)
            const color = `hsl(${hue}, 90%, 30%)`; // HSL color representation for valid ratings
            const nextValue = arr[index + 1] || max + 1;
            const nextPercentage = (filteredData.filter((v) => v < nextValue).length / totalValues) * 100;
            return `${color} ${nextPercentage}%`;
        });
        gradientStyle = `linear-gradient(to right, ${gradientStops.join(', ')})`;
    }



    useEffect(() => {
        const chartElement = chartRef.current;
        chartElement.style.width = '0%';
        setTimeout(() => {
            chartElement.style.transition = 'width 1s ease-in-out';
            chartElement.style.width = `${barWidth}%`;
        }, 100);
    }, [barWidth]);

    return  (
        <div className="score-bar" style={{ width: '100%', height: barHeight + 20, display: 'flex', alignItems: 'center' }}>
            <div className="score-bar__chart" ref={chartRef} style={{ width: '100%', height: `${barHeight}px` }}>
                {(totalValues > 0) && (<GradientBar gradientStyle={gradientStyle} barHeight={barHeight} />)}
            </div>
            <div className="score-bar__info" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                {(totalValues > 0) && labelPosition === 'right' && (
                    <div className="score-bar__average" style={{ fontSize: `${barHeight * 0.8}px`, marginRight: '10px' }}>
                        {averageValue.toFixed(2)}
                    </div>
                )}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {(totalValues > 0) && labelPosition === 'bottom' && <div className="score-bar__metric-name">{metricName}</div>}
                    {(totalValues > 0) && labelPosition === 'right' && <div className="score-bar__metric-name">{metricName}</div>}
                </div>
                {(totalValues > 0) && labelPosition === 'bottom' && (
                    <div className="score-bar__average" style={{ fontSize: `${barHeight * 0.8}px`, marginLeft: '10px' }}>
                        {averageValue.toFixed(2)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ScoreBar;
