import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SharedDataProvider, useSharedData} from "./components/contexts/SharedDataContext";

const onRedirectCallback = (appState) => {
    console.log(appState);
    /*alert("You're logged in!");
    window.history.replaceState(
        {},
        document.title,
        appState?.returnTo || window.location.pathname
    );*/
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <SharedDataProvider>
      <Auth0Provider
          domain="dev-qrxq6v02ydh4dcyf.us.auth0.com"
          clientId="n7XE34S9PbTCFTFOSFI2KJ2S0Nu7lBbs"
          authorizationParams={{
              redirect_uri: window.location.origin,
              audience: "https://audience.auth0.leadlensai.com",
              scope: "read:current_user update:current_user_metadata"
          }}
          audience="https://audience.auth0.leadlensai.com"
          onRedirectCallback={onRedirectCallback}
      >
    <App />
      </Auth0Provider>
      </SharedDataProvider>
  </React.StrictMode>
);
reportWebVitals();
