import React, { useState, useEffect } from 'react';
import { Descriptions, Typography } from 'antd';
import EditableDescription from "./widgets/EditableDescription";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import _ from 'lodash';

const { Paragraph } = Typography;

const DynamicDetailView = ({ call, setSelectedCall }) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [detailConfig, setDetailConfig] = useState(null);


    useEffect(() => {
        if (call) {
            fetchDetailConfig();
        }
    }, [call]);

    const fetchDetailConfig = async () => {
        const accessToken = await getAccessTokenSilently({
            audience: "https://audience.auth0.leadlensai.com",
            scope: "openid profile email",
        });
        try {
            const response = await axios.get('/api/config/detailview', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    view: 'CallDetail', // Define the view
                    user: user.sub
                }
            });
            setDetailConfig(response.data);
        } catch (error) {
            console.error('Error fetching detail configuration:', error);
        }
    };

    if (!call) {
        return <div>Select a call from the list to view details.</div>;
    }

    if (!detailConfig) {
        return <div>Loading configuration...</div>;
    }

    const updateCallField = async (callId, field, value) => {
        try {
            const accessToken = await getAccessTokenSilently({
                audience: "https://audience.auth0.leadlensai.com",
                scope: "openid profile email",
            });
            await axios.patch(`/api/calls/${callId}`, { [field]: value }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error updating call field:', error);
            throw error;
        }
    };

    const handleSaveField = async (field, updatedValue) => {
        try {
            console.log(`Updating ${field} on Call ${call.id} to ${updatedValue}`);
            await updateCallField(call.id, field, updatedValue);
            _.set(call, field, updatedValue);
            setSelectedCall(call);
        } catch (error) {
            console.error('Error updating field:', error);
        }
    };

    const renderField = (fieldConfig, callData) => {
        const { label, key, inputType, options, editable, ratingBadgeProps, renderType } = fieldConfig;
        const value = key.split('.').reduce((acc, part) => acc && acc[part], callData);

        if (renderType === 'html') {
            return <div dangerouslySetInnerHTML={{ __html: value }} />;
        }

        if (editable) {
            return (
                <EditableDescription
                    item={value}
                    inputType={inputType}
                    options={options}
                    onSave={(updatedValue) => handleSaveField(key, updatedValue)}
                    ratingBadgeProps={ratingBadgeProps}
                />
            );
        } else {
            return <span>{value}</span>;
        }
    };

    return (
        <div className={'detail-view'}>
            <div className={"detail-header"}>
                <h1><span>{call.customer_name}</span></h1>
                <h2>{call.company_name} -  {call.ai_q1}</h2>
            </div>
            {detailConfig.sections.map((section) => (
                <Descriptions title={section.title} bordered column={1} key={section.title}>
                    {section.fields.map((field) => (
                        <Descriptions.Item label={field.label} key={field.key}>
                            {renderField(field, call)}
                        </Descriptions.Item>
                    ))}
                </Descriptions>
            ))}
        </div>
    );
};

export default DynamicDetailView;
