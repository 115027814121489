import React from 'react';

const RatingBadge = ({ rating, radius, backgroundColor, color, style, render, dynamicColor, label, containerDisplay, colorFontOnly }) => {
    const displayRating = (rating === null || isNaN(rating)) ? 'N/A' : rating;

    const getRatingColor = (rating) => {
        // Check if rating is null or not a numeric value
        if (rating === null || isNaN(rating) || (rating < 0) || (rating > 100)) {
            return backgroundColor ? backgroundColor : 'gray'; // Return gray color for invalid ratings
        }

        const hue = ((rating - 1) * 12); // Calculate hue from red to green (1 = 0, 10 = 108)
        return `hsl(${hue}, 90%, 30%)`; // HSL color representation for valid ratings
    };

    return (
        <div className={'rating-badge-container'} style={{ display: containerDisplay ? containerDisplay : 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {label && <h2 className={'rating-badge-title'} style={{ marginBottom: '10px' }}>{label}</h2>}
            <div
                style={{
                    ...style,
                    width: (radius ? radius : '50px'),
                    height: (radius ? radius : '50px'),
                    borderRadius: '50%',
                    backgroundColor: dynamicColor ? (colorFontOnly ? "inherit" : getRatingColor(rating)) : backgroundColor,
                    color: dynamicColor ? (colorFontOnly ? getRatingColor(rating) : 'white') : null,
                    display: (rating === null || isNaN(rating)) ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    borderColor : dynamicColor ? getRatingColor(rating) : null,
                    borderStyle: dynamicColor ? "solid" : null,
                    borderWidth: "2px",
                }}
            >
                {render ? render(rating) : rating}
            </div>
        </div>
    )
}

export default RatingBadge;
