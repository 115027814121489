import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

class API {

    static async getAgentSummary(accessToken, agentName, companyName) {
        try {
            const response = await axios.get('/api/agent-summary', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    page: 1,
                    pageSize: 1000,
                    agentName,
                    companyName,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching data: ', error);
            throw error;
        }
    }
}

export default API;
