import { React, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout, ConfigProvider } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import './styles/custom.css'; // LeadLens custom CSS file
import NavBar from './components/NavBar'; // Assume NavBar is in the components folder
import Dashboard from './components/Dashboard'; // Dashboard component
import { io } from 'socket.io-client';
import { Alert } from 'antd';
import { useSharedData} from "./components/contexts/SharedDataContext";
import CallAuditView from "./components/experiments/CallAuditView";
import CallEvaluationView from "./components/experiments/CallEvaluationView";

const { Content, Footer } = Layout;

const App = () => {

    const { isLoading, isAuthenticated, loginWithRedirect, error, getAccessTokenSilently } = useAuth0();
    const [socket, setSocket] = useState(null);
    const [selectedCompanyNames, setSelectedCompanyNames] = useState([]);

    const handleCompanyNamesChange = (selectedCompanyNames) => {
        if(selectedCompanyNames != null && !(selectedCompanyNames instanceof Array)) {
            selectedCompanyNames = [selectedCompanyNames];
        }
        else {
            setSelectedCompanyNames(selectedCompanyNames);
        }

    };

    const { showNotification, setShowNotification, lastMessage, setLastMessage, selectedRecord, setSelectedRecord, detailViewType, setDetailViewType } = useSharedData();

    useEffect(() => {
        const connectSocket = async () => {
            try {
                if (isAuthenticated) {
                    const token = await getAccessTokenSilently();
                    console.log("Connecting socket....");
                    const newSocket = io('/', {
                        query: { token },
                        timeout: 5000, // 5 seconds timeout
                        reconnectionAttempts: 1, // Optional: limit reconnection attempts
                        transports: ['websocket', 'polling']
                    });

                    if(newSocket != null) {
                        setSocket(newSocket);

                        newSocket.on('newCall', (data) => {
                            setLastMessage(data);
                            setShowNotification(true);
                        });

                        // Event listeners
                        newSocket.on('connect', () => {
                            console.log('Socket connected');
                        });

                        newSocket.on('connect_error', (err) => {
                            console.error('Socket connection error:', err);
                        });

                        newSocket.on('connect_timeout', () => {
                            console.error('Socket connection timed out');
                            newSocket.disconnect();
                        });

                    }


                    return () => {
                        if(newSocket)
                            newSocket.disconnect();
                    };
                }
            }
            catch (e) {
                console.error('Failed to connect websocket : ' + e);
            }

        };

        connectSocket();
    }, [isAuthenticated]);

    const applyTheme = (themeConfig) => {
        ConfigProvider.config({ theme: themeConfig });
    };
    window.applyTheme = applyTheme;

    useEffect(() => {
        if (!isLoading && !isAuthenticated && !error) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect, error]);

    const AuthCallbackHandler = () => {
        const { isLoading, isAuthenticated, error, user } = useAuth0();

        useEffect(() => {
            console.log('Auth Handler Called');
            console.log('Loading = ' + isLoading);
            console.log('Authenticated = ' + isAuthenticated);
            console.log('User:');
            console.dir(user);
            console.log('Error:');
            console.dir(error);

            if (!isLoading && isAuthenticated) {
                // Redirect to home or dashboard after successful authentication
                window.location.href = '/';
            }
            // Handle error or loading states as needed
        }, [isLoading, isAuthenticated, error]);

        return <div>Loading...</div>; // Display a loading message or spinner
    };

    return (

        <ConfigProvider theme={require('./styles/theme.json')}>
            <Router>
                <Layout style={{ minHeight: '100vh' }}>
                    <NavBar onCompanyNamesChange={handleCompanyNamesChange} />
                    <Layout className="site-layout">
                        <Content style={{ margin: '0 16px', overflow: 'initial', height: 'calc(100vh - 70px - 16px - 16px)' }}>
                            <Routes>
                                <Route path="/auth-callback" element={<AuthCallbackHandler />} />
                                <Route path="/" element={isAuthenticated ? <Dashboard view="Calls" selectedCompanyNames={selectedCompanyNames} /> : <div />} />
                                <Route path="/agents" element={isAuthenticated ? <Dashboard view="Agents" selectedCompanyNames={selectedCompanyNames} /> : <div />} />
                                <Route path="/calls" element={isAuthenticated ? <Dashboard view="Calls" selectedCompanyNames={selectedCompanyNames} /> : <div />} />
                                <Route path="/live-calls" element={isAuthenticated ? <CallAuditView /> : <div />} />
                                <Route path="/test-configuration" element={isAuthenticated ? <CallEvaluationView /> : <div />} />
                            </Routes>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>LeadLensAI ©2024 created by All Points Digital</Footer>
                    </Layout>
                </Layout>
            </Router>
        </ConfigProvider>
    );
};

export default App;
