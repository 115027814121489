import React from 'react';
import { Card, Col, Row, Carousel as AntCarousel } from 'antd';
import ScoreBar from './widgets/ScoreBar';
import ScoreBarCollection from './widgets/ScoreBarCollection';
import moment from 'moment';
import RatingBadge from "./widgets/RatingBadge";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';

const getGradeColor = (score) => {
    if (score >= 90) return 'darkgreen';
    if (score >= 80) return 'green';
    if (score >= 70) return 'gold';
    if (score >= 60) return 'orange';
    return 'darkred';
};

const roundValue = (value, precision = 0) => {
    const multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
};

const DynamicScoreCardView = ({ agentSummary }) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [scoreCardConfig, setScoreCardConfig] = React.useState(null);

    React.useEffect(() => {
        fetchScoreCardConfig();
    }, []);

    const fetchScoreCardConfig = async () => {
        const accessToken = await getAccessTokenSilently({
            audience: "https://audience.auth0.leadlensai.com",
            scope: "openid profile email",
        });
        try {
            const response = await axios.get('/api/config/scorecards', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    view: 'AgentDetail',
                    user: user.sub
                }
            });
            setScoreCardConfig(response.data);
        } catch (error) {
            console.error('Error fetching scorecard configuration:', error);
        }
    };

    if (!agentSummary) {
        return <div>Select an agent from the list to view details.</div>;
    }

    if (!scoreCardConfig) {
        return <div>Loading configuration...</div>;
    }

    const renderScoreBarCollection = (collection) => {
        return (
            <ScoreBarCollection range={collection.range} axisWidth={2} axisColor="#207edc" numTicks={11} xLabel={collection.label}>
                {collection.metrics.map((metric) => (
                    <ScoreBar
                        key={metric.key}
                        barHeight={20}
                        data={agentSummary?.summaryInfo[metric.key]}
                        range={collection.range}
                        metricName={metric.label}
                    />
                ))}
            </ScoreBarCollection>
        );
    };

    const renderRatingBadge = (card) => {
        let value;
        if (card.key === 'agentGrade') {
            value = agentSummary.summaryInfo['questions.AgentScore']?.reduce((a, b) => a + b, 0) / agentSummary.summaryInfo['questions.AgentScore']?.length || 0;
        } else if (card.key === 'totalCalls') {
            value = agentSummary.calls.length;
        } else {
            value = agentSummary[card.key];
        }

        const roundedValue = roundValue(value, card.precision || 0);

        return (
            <Card key={card.key} style={{ height: 200, marginRight: 20, backgroundColor: card.color || 'white' }}>
                <RatingBadge
                    label={card.label}
                    containerDisplay={'flex'}
                    radius={'100px'}
                    rating={roundedValue}
                    render={(value) => value}
                    backgroundColor={card.color || 'white'}
                    dynamicColor={card.dynamicColor || false}
                    style={{ fontSize: '36px', fontWeight: "bold" }}
                />
            </Card>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Row>
                <Col flex={1}>
                    <div className={"detail-header"}>
                        <h1><span>{agentSummary.agentName}</span></h1>
                        <h2>{agentSummary.companyName} - Agent</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                {scoreCardConfig.ratingBadges.map(renderRatingBadge)}
                <Col span={12}>
                    {scoreCardConfig.scoreBarCollections.map(renderScoreBarCollection)}
                </Col>
                {scoreCardConfig.infoCards.map(renderRatingBadge)}
            </Row>
        </div>
    );
};

export default DynamicScoreCardView;
