import React, { useState, useEffect, useRef } from 'react';
import {Alert, Layout, Button, Space} from 'antd';
import DetailView from './DetailView';
import AgentDetailView from './AgentDetailView';
import DataView from './DataView';
import API from './controllers/api-controller';
import {useAuth0} from "@auth0/auth0-react";
import {useSharedData} from "./contexts/SharedDataContext";
import { useSpring, animated } from 'react-spring';
import { EyeOutlined } from '@ant-design/icons';
import DynamicDetailView from "./DynamicDetailView";
import DynamicScoreCardView from "./DynamicScorecardView";

const { Content } = Layout;

const Dashboard = (props) => {

    const [detailViewHeight, setDetailViewHeight] = useState('45%');

    const [dataViewHeight, setDataViewHeight] = useState(window.innerHeight * 0.55);
    const [isDragging, setIsDragging] = useState(false);
    const contentRef = useRef(null);


    const {  showNotification, setShowNotification, lastMessage, setLastMessage, selectedRecord, setSelectedRecord, detailViewType, setDetailViewType  } = useSharedData();

    const { getAccessTokenSilently } = useAuth0();



    const handleRowClick = (record) => {
        setSelectedRecord(record);
        setDetailViewType('CallDetail');
    };

    const handleSelectAgent = async (agentName) => {
        let token = await getAccessTokenSilently();
        let agentSummary = await API.getAgentSummary(token,agentName);
        setSelectedRecord(agentSummary);
        setDetailViewType('AgentDetail');
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const newHeight = e.clientY - contentRef.current.getBoundingClientRect().top;
        const totalAvailableHeight = contentRef.current.clientHeight;
        const maxDetailViewHeight = totalAvailableHeight - 100; // Reserve minimum 100px for DataView
        const minDetailViewHeight = totalAvailableHeight - 600; // DataView max 600px

        if (newHeight >= minDetailViewHeight && newHeight <= maxDetailViewHeight) {
            setDetailViewHeight(`${newHeight}px`);
            // Calculate and set DataView height only if it has changed
            const newDataViewHeight = totalAvailableHeight - newHeight;
            if (newDataViewHeight !== dataViewHeight) {
                setDataViewHeight(Math.max(100, Math.min(600, newDataViewHeight)));
            }
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const alertRef = useRef(null);
    const contentWidth = contentRef.current ? contentRef.current.clientWidth : 0;

    const [alertVisible, setAlertVisible] = useState(false);

    const alertAnimation = useSpring({
        from: { transform: 'translateY(-100%)' },
        to: { transform: alertVisible ? 'translateY(0%)' : 'translateY(-100%)' },
        config: { tension: 300, friction: 20 },
        onRest: () => {
            if (!alertVisible) {
                alertRef.current.style.display = 'none';
            }
        },
    });

    useEffect(() => {
        if (showNotification) {
            setAlertVisible(true);
            alertRef.current.style.display = 'block';
        }
    }, [showNotification]);

    const handleViewLead = () => {
        setDetailViewType('CallDetail');
        setSelectedRecord(lastMessage);
        //setShowNotification(false);
        setAlertVisible(false);
    };

    const handleClose = () => {
        //setShowNotification(false);
        setAlertVisible(false);
    };

    useEffect(() => {
        if (!alertVisible) {
            const timer = setTimeout(() => {
                setShowNotification(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [alertVisible]);

    return (
        <Content ref={contentRef} style={{ margin: '24px 16px', padding: 24, background: '#fff', height: 'calc(100vh - 70px - 32px)', overflow: 'auto', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {showNotification && (
                <animated.div ref={alertRef} style={{ ...alertAnimation, position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000, width: '80%', margin: '0 auto', display: 'none' }}>
                    <Alert
                        banner={true}
                        message={`New Inbound Lead`}
                        description={`${lastMessage?.company_name}: ${lastMessage?.customer_name}`}
                        type="info"
                        showIcon
                        closable
                        onClose={handleClose}
                        action={
                            <Button type="primary" onClick={handleViewLead} icon={<EyeOutlined />} style={{ width: '150px', margin: '20px' }}>
                                View
                            </Button>
                        }
                        style={{ display: 'flex', alignItems: 'center' }}
                    />
                </animated.div>
            )}

            <div style={{ height: detailViewHeight, overflow: 'auto' }}>
                {detailViewType === 'CallDetail' && (
                    <DynamicDetailView call={selectedRecord} setSelectedCall={setSelectedRecord}/>
                )}
                {detailViewType === 'AgentDetailOld' && (
                    <AgentDetailView agentSummary={selectedRecord} />
                )}
                {detailViewType === 'AgentDetail' && (
                    <DynamicScoreCardView agentSummary={selectedRecord} />
                )}
            </div>
            <div onMouseDown={handleMouseDown} style={{ cursor: 'row-resize', height: '3px', background: '#eee', margin: '15px' }}></div>
            <div style={{ flex: 1, overflow: 'hidden' }}>
                <DataView view={props.view} key="dataView1" onRowClick={handleRowClick} onSelectAgent={handleSelectAgent} triggerHeightUpdate={dataViewHeight}  selectedCompanyNames={props.selectedCompanyNames}  />
            </div>
        </Content>
    );
};

export default Dashboard;
